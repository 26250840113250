/* InstagramFeed.css */
.instagram-feed {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.instagram-post {
  width: calc(33.33% - 32px);
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #000;
  border-radius: 16px;
  overflow: hidden;
}

.post-image {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.post-video {
  width: 100%;
  height: 60%;
}

.post-caption {
  font-size: 0.9rem;
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-name {
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 8px;
}

.instagram-logo {
  width: 16px;
  height: 16px;
}

.post-link {
  display: flex;
  align-items: center;
  margin-top: auto; /* pin to the bottom */
  margin-bottom: 16px; /* add a margin above */
  color: #000;
  text-decoration: none;
}

/* Media query for smaller screens */
@media (max-width: 1280px) {
  .instagram-post {
    width: calc(50% - 32px);
  }
}

/* Media query for even smaller screens */
@media (max-width: 680px) {
  .instagram-post {
    width: 100%;
  }
}
